<template lang="pug">
.posts
	.posts-head
		.sort(@click="isSortOpen=!isSortOpen")
			p დალაგება
			img(src="@/assets/images/Icon-ionic-ios-arrow-up.svg" :class="{rotate: isSortOpen}")
			.sort-absolute-cont(v-if="isSortOpen" )
				.sort-item
					span ic
					p დროის მიხედვით
				.sort-item
					span ic
					p დაინტერესების მიხედვით
		router-link(:to="{name: 'add-post'}") ახალი პოსტი
	post(v-for="post in posts.results" :data="post")
</template>

<script>
import {mapGetters} from "vuex";
import Post from "./Post";

export default {
	name: "Posts",
	components: {
		Post
	},
	data() {
		return {
			isSortOpen: false
		}
	},
	computed: {
		...mapGetters({
			posts: "posts/posts"
		})
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	unmounted() {
		window.removeEventListener("scroll", this.handleScroll)
	},
	methods: {
		handleScroll() {
			// window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop
			const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
			if (bottomOfWindow && this.posts.next) {
				this.$store.dispatch('posts/loadMorePosts')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.posts {
	padding-bottom: 20px;

	.posts-head {
		display: flex;
		justify-content: space-between;

		.sort {
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			position: relative;

			> p {
				font: normal normal bold 16px/19px 'Helvetica-Bold';
				letter-spacing: 0;
				color: #212121;
			}

			> img {
				width: 9px;
				height: 5px;
				transition: .3s;
			}

			> img.rotate {
				transform: rotate(180deg);
			}

			> .sort-absolute-cont {
				position: absolute;
				top: 100%;
				width: fit-content;
				margin-top: 5px;
				background: #FFFFFF 0 0 no-repeat padding-box;
				box-shadow: 0 3px 20px #00000029;
				border-radius: 5px;
				padding: 10px;

				.sort-item {
					display: flex;
					align-items: center;
					gap: 10px;
					white-space: nowrap;
					margin-bottom: 10px;
					cursor: pointer;
					user-select: none;

					> p {
						font-size: 14px;
						color: #212121;
					}
				}

				.sort-item:last-child {
					margin: 0;
				}
			}
		}

		> a {
			border: none;
			background: #8E9CEB 0 0 no-repeat padding-box;
			box-shadow: 0 3px 20px #0000000D;
			border-radius: 15px;
			font: normal normal bold 16px/19px 'Helvetica-Bold';
			letter-spacing: 0;
			color: #FFFFFF;
			padding: 10px;
			text-decoration: none;
		}
	}
}
</style>
