<template lang="pug">
.active-offers
	h2 ᲐᲥᲢᲘᲣᲠᲘ ᲨᲔᲗᲐᲕᲐᲖᲔᲑᲔᲑᲘ
	.active-offers-list(v-if="activeOffers && activeOffers.length")
		UserOffer(v-for="offer in activeOffers" :key="offer.id" :data="offer")
	.active-offers-list(v-else )
		p აქტიური შეთავაზებები არ გაქვთ
</template>

<script>
import {mapGetters} from "vuex";
import UserOffer from "./UserOffer.vue";

export default {
	name: "ActiveOffers",
	components: {UserOffer},
	computed: {
		...mapGetters({
			activeOffers: "user/activeOffers"
		})
	}
}
</script>

<style lang="scss" scoped>
.active-offers {
	max-height: 550px;

	h2 {
		font: normal normal bold 16px/19px 'Helvetica-Bold';
		letter-spacing: 0;
		color: #212121;
		margin-bottom: 13px;
	}

	.active-offers-list {
		height: 100%;
		max-height: 100%;
		overflow: hidden;
		overflow-y: auto;
		background: #FFFFFF 0 0 no-repeat padding-box;
		box-shadow: 0 3px 20px #00000029;
		border-radius: 5px;
		padding: 15px;

		:deep(.user-offer:last-child) {
			.user-offer-divider {
				display: none;
			}
		}
	}

	.active-offers-list::-webkit-scrollbar {
		width: 4px;
	}

	.active-offers-list::-webkit-scrollbar-track {
		background-color: #FFFFFF;
	}

	.active-offers-list::-webkit-scrollbar-thumb {
		background-color: #E0DDDD;
		border-radius: 8px;
	}
}
</style>
