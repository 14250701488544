<template lang="pug">
.post
    .post-user
        img(:src="postAuthorImage")
        p {{ postAuthor }}
    .post-information
        p(v-html="data.description")
        .post-date
            img(src="@/assets/images/Icon-feather-calendar.svg")
            p {{ postDuration }}
        .post-information-bottom
            h3 {{ postSalary }}
            .post-information-bottom-right
                p {{ data.interests }}
                img(src="@/assets/images/Icon-ionic-ios-heart-empty-black.svg")
        div(v-if="data.vacancies && data.vacancies.length" )
            .post-information-divider
            .open-post-filters(@click="filtersShow=!filtersShow")
                .open-button
                    h3 ფილტრები
                    img(src="@/assets/images/Icon-ionic-ios-arrow-up.svg" :class="{rotate: filtersShow}")
                p კატეგორია
            .post-filters(:class="{open: filtersShow}")
                UserFilter(v-for="item in data.vacancies" :key="item.id" :data="item" buttonText="შეთავაზების გაგზავნა")
</template>

<script>
import dateFormat from "../../middleware/dateFormat";
import UserFilter from "@/components/layout/UserFilter";
import defaultImage from "../../assets/images/defaultImage.png"

export default {
    name: "post",
    components: {UserFilter},
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            filtersShow: false,
        }
    },
    computed: {
        postAuthor() {
            return this.data.user.first_name + " " + this.data.user.last_name
        },
        postAuthorImage() {
            return this.data.user.profile_image !== null ? this.data.user.profile_image : defaultImage
        },
        postSalary() {
            return this.data.is_salary ? "$ ანაზღაურებადი" : " "
        },
        postDuration() {
            return dateFormat(this.data.start_date).dayNumber + " " + dateFormat(this.data.start_date).month + " - " + dateFormat(this.data.end_date).dayNumber + " " + dateFormat(this.data.end_date).month
        }
    }
}
</script>

<style lang="scss" scoped>
.post {
    margin: 20px 0;
    overflow: hidden;

    .post-user {
        margin-bottom: 10px;
        display: flex;
        gap: 5px;
        align-items: center;

        img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            font-size: 14px;
            color: #212121;
        }
    }

    .post-information {
        padding: 20px;
        box-shadow: 0 3px 20px #00000029;
        border-radius: 5px;
        background-color: #FFFFFF;

        > p {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0;
            color: #6E6E6E;
            text-align: left;
            margin-bottom: 10px;
        }

        .post-date {
            display: flex;
            justify-content: right;
            gap: 6px;


            span {
                color: #4354B4;
            }

            p {
                font-size: 14px;
                color: #4354B4;
            }
        }

        .post-information-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            h3 {
                font: normal normal bold 14px/16px 'Helvetica-Bold';
                color: #4354B4;
            }

            .post-information-bottom-right {
                display: flex;
                align-items: center;
                gap: 5px;

                p {
                    font-size: 16px;
                    color: #6E6E6E;
                }

                img {
                    width: 25px;
                    height: 24px;
                }
            }
        }

        .post-information-divider {
            width: 100%;
            height: 1px;
            background-color: #DDDDDD;
            margin: 10px 0;
        }

        .open-post-filters {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .open-button {
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                    transition: .3s;
                }

                img.rotate {
                    transform: rotate(180deg);
                }
            }

            h3 {
                font: normal normal bold 13px/15px 'Helvetica-Bold';
                letter-spacing: 0;
                color: #212121;
                cursor: pointer;
            }

            p {
                font: normal normal 300 13px/16px 'Helvetica-Light';
                letter-spacing: 0;
                color: #212121;
            }
        }

        .post-filters {
            width: 100%;
            max-height: 0;
            transition: .4s;
            opacity: 0;
            visibility: hidden;
        }

        .post-filters.open {
            max-height: 100vh;
            opacity: 1;
            visibility: visible;
            margin-top: 15px;
        }
    }
}
</style>
