<template lang="pug">
.user-offer(@click="handleOfferClick" :class="[data.status === 0 ? 'pending' : '']")
    .user
        img(:src="profileImage")
        p {{ userName }}
    p {{ data.biography.description }}
    .user-offer-divider(v-if="!hideDivider" )
    .offer-status
        .pending(v-if="data.status === 0" )
            img(src="@/assets/images/Icon-feather-arrow-up-circle-yellow.svg")
        .accept(v-else-if="data.status === 1" )
            .tag
                | შეთანხმება
            img(src="@/assets/images/Icon-feather-arrow-up-circle-green.svg")
        .reject(v-else)
            .tag
                | უარი
</template>

<script>
import {mapGetters} from "vuex";
import defaultImage from "../../assets/images/defaultImage.png"

export default {
    name: "UserOffer",
    props: {
        hideDivider: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        ...mapGetters({
            profileData: 'user/profileData'
        }),
        userName() {
            return this.data.biography.user.first_name + " " + this.data.biography.user.last_name
        },
        profileImage() {
            const profileImage = this.data.biography.images.find(item => item.is_profile_image)
            return profileImage !== undefined ? profileImage.image : defaultImage
        }
    },
    methods: {
        handleOfferClick() {
            if (this.profileData.status > 1 && this.data.status === 0) {
                // if user is employer and offer status is pending
                this.$router.push({name: 'post-detail', params: {postId: this.data.post_id}})
            } else {
                // code which run for actor...
            }

            // post content in modal:
            // this.$store.commit("modal/setModalComponent", {
            //     type: "PostOffer",
            //     withContainer: false,
            //     fullscreen: false,
            //     componentData: {
            //         data: this.data
            //     }
            // })
        }
    }
}
</script>

<style lang="scss" scoped>
.user-offer.pending {
    cursor: pointer;
}

.user-offer {
    position: relative;
    user-select: none;

    > .user {
        display: flex;
        align-items: center;
        gap: 5px;

        > img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            object-fit: cover;
        }

        > p {
            color: #212121;
            font-size: 14px;
        }
    }

    > p {
        font-size: 14px;
        line-height: 19px;
        color: #6E6E6E;
        padding: 10px 0;
        max-width: 270px;
    }

    .user-offer-divider {
        width: 100%;
        height: 1px;
        background-color: #DDDDDD;
        margin: 10px 0;
    }

    .offer-status {
        position: absolute;
        top: 5px;
        right: 10px;

        .accept, .reject {
            display: flex;
            align-items: center;
            gap: 5px;

            .tag {
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 7px;
                font: normal normal bold 10px/12px 'Helvetica-Bold';
                letter-spacing: 0;
                color: #FFFFFF;
            }
        }

        .reject > .tag {
            background-color: #F86363;
        }

        .accept > .tag {
            background-color: #92EB8E;
        }
    }
}
</style>
