<template lang="pug">
.filters
	h4 ფილტრები
	.selected-filters
		span(v-for="item in filtersBreadcrumbs" @click="removeFilter(item)")  {{ item.title }} x
	.form
		.buttons
			button(@click="clearFilters") გასუფთავება
			button(@click="setFilters") გაფილტვრა
		.photo-search(v-if="buttonText.length" )
			button {{ buttonText }}
		base-widget(v-for="data in widgetsData" :data="data" @update="filterUpdate")
</template>

<script>
import TokenService from "../../services/token.service";
import {mapGetters} from "vuex";

import DefaultButton from "../layout/DefaultButton";
import BaseWidget from "../widgets/BaseWidget";
import DSelect from "../widgets/DefaultSelect";
import DefaultSlider from "../widgets/DefaultSlider";
import DefaultMultiSelect from "../widgets/DefaultMultiSelect";
import DefaultDatePickers from "../widgets/DefaultDatePickers";

export default {
	name: "filters",
	components: {
		DefaultButton,
		DSelect,
		BaseWidget,
		DefaultSlider,
		DefaultMultiSelect,
		DefaultDatePickers
	},
	emits: ['fetchPageData'],
	props: {
		buttonText: {
			type: String,
			default: ''
		},
		filterBiography: {
			type: Boolean,
			default: false
		},
		runFilter: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			widgetsData: [
				{type: 'DMultiSelect', id: 'tags', title: 'ტეგები', placeholder: 'კატეგორია'},
				{type: 'DSelect', id: 'gender', title: 'სქესი', placeholder: 'ნებისმიერი'},
				{type: 'DSelect', id: 'eye_color', title: 'თვალის ფერი', placeholder: 'ნებისმიერი'},
				{type: 'DSelect', id: 'hair_color', title: 'თმის ფერი', placeholder: 'ნებისმიერი'},
				{type: 'DSelect', id: 'skin_color', title: 'კანის ფერი', placeholder: 'ნებისმიერი'},
				{type: 'DMultiSelect', id: 'characteristics', title: 'სხვა მახასიათებლები', placeholder: 'ნებისმიერი'},
				{type: 'DDatePickers', id: 'date', title: 'დრო'},
				{type: 'DSlider', id: 'age', title: 'ასაკი'},
				{type: 'DSlider', id: 'height', title: 'სიმაღლე'},
				{type: 'DSlider', id: 'weight', title: 'წონა'}
			]
		}
	},
	computed: {
		...mapGetters({
			selectedFilters: "posts/selectedFilters",
			filtersBreadcrumbs: "posts/filtersBreadcrumbs",
			tags: "posts/tags",
			eye_color: "posts/eye_color",
			hair_color: "posts/hair_color",
			skin_color: "posts/skin_color",
			gender: "posts/gender",
			date: "posts/date",
			age: "posts/age",
			height: "posts/height",
			weight: "posts/weight",
			characteristics: "posts/characteristics"
		})
	},
	mounted() {
		this.filterOnLoad()
	},
	watch: {
		runFilter(NewVal) {
			if (NewVal) this.setFilters()
		}
	},
	methods: {
		clearFilters() {
			// Clear Query And Refresh Widgets
			this.$router.replace({query: {}}).finally(() => {
				this.emitter.emit("filterUpdated");
			})

			// Clear Selected Filters And FiltersBreadcrumbs From Story
			this.$store.commit("posts/setSelectedFilter", [])
			this.$store.commit("posts/setFiltersBreadcrumb", [])

			// Fetch data Without Filters
			if (this.filterBiography) {
				TokenService.removeItem('selectedFilter')
				this.$store.dispatch("user/fetchBiographies")
				this.$emit('removeSelected')
			} else {
				this.$store.dispatch("posts/fetchPosts")
			}
		},
		removeFilter(item) {
			const removeFilterId = this.selectedFilters.findIndex(el => el.filterId === item.filterId && el.id === item.id)
			this.$store.commit('posts/removeSelectedFilter', removeFilterId)
			this.setFilters(null, true)
		},
		setFilters(e, emitWidgets = false) {
			this.$store.commit("posts/setFiltersBreadcrumb", [...this.selectedFilters])
			// Call Prepare Query Method
			const query = this.PrepareQuery(this.filtersBreadcrumbs)

			// Call Action From Vuex
			if (this.filterBiography) {
				this.$store.dispatch("user/fetchBiographies", query).then(() => {
					this.$router.replace({query}).then(() => {
						this.emitter.emit("filterUpdated");
					})
				})
			} else {
				this.$store.dispatch("posts/fetchPosts", query).then(() => {
					this.$router.replace({query}).then(() => {
						if (emitWidgets) this.emitter.emit("filterUpdated");
					})
				})
			}
		},
		PrepareQuery(data) {
			let query = {}
			let tags = [], characteristics = []
			data.forEach(item => {
				if (item.filterId === "date") {
					query.start_date = item.value[0]
					query.end_date = item.value[1]
				} else if (item.filterId === "age") {
					query.age_min = item.value[0]
					query.age_max = item.value[1]
				} else if (item.filterId === "height") {
					query.height_min = item.value[0]
					query.height_max = item.value[1]
				} else if (item.filterId === "weight") {
					query.weight_min = item.value[0]
					query.weight_max = item.value[1]
				} else if (item.filterId === "tags") {
					tags.push(item.id)
				} else if (item.filterId === "characteristics") {
					characteristics.push(item.id)
				} else
					query[item.filterId] = item.id
			})
			query.tags = tags
			query.characteristics = characteristics

			return query
		},
		filterOnLoad() {
			const query = this.$route.query
			this.$emit('fetchPageData', Object.keys(query).length !== 0 ? query : null)
		},
		filterUpdate({e, options, preventAdd, firstLoad, addThis, multi}) {
			let removeFilterId

			if (!multi) { // Find Related Selected Item
				removeFilterId = this.selectedFilters.findIndex(el => el.filterId === e.filterId)
			} else { // Find Already Selected
				removeFilterId = this.selectedFilters.findIndex(el => el.filterId === e.filterId && el.id === e.selectedId)
			}

			if (removeFilterId !== -1 && !(multi && firstLoad)) { // Remove Selected Item
				this.$store.commit('posts/removeSelectedFilter', removeFilterId)
			}

			// Find New Selected Item
			const selectedIndex = addThis ? null : options.findIndex(el => el.id === e.selectedId)

			if (!preventAdd && !(multi && removeFilterId !== -1)) {
				const option = addThis ? e : {
					...options[selectedIndex],
					title: e.titleStart + options[selectedIndex].title,
					filterId: e.filterId
				}
				this.$store.commit("posts/addSelectedFilter", option)

				const isInBreadcrumb = this.filtersBreadcrumbs.findIndex(el => el.filterId === e.filterId && el.id === e.selectedId)

				if (firstLoad && isInBreadcrumb === -1) {
					this.$store.commit("posts/addFiltersBreadcrumb", option)
				}
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.filters {

	h4 {
		color: #212121;
		font-size: 16px;
		font-family: 'Helvetica-Bold', sans-serif;
		margin-bottom: 15px;
	}

	.selected-filters {
		margin-bottom: 10px;

		span {
			cursor: pointer;
			display: inline-block;
			padding: 4px 8px;
			margin: 2px;
			font-size: 12px;
			background-color: #B2B2B2;
			color: white;
			border-radius: 4px;
		}
	}

	.form {
		box-shadow: 0 3px 20px #00000029;
		padding: 15px;

		.buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 18px;

			& > button {
				border: none;
				border-radius: 10px;
				font: normal normal normal 14px/14px 'Helvetica-Bold';
				color: #FFFFFF;
				background: #909090 0 0 no-repeat padding-box;
				padding: 7px 15px;
			}

			& > button:last-child {
				background: #92EB8E 0 0 no-repeat padding-box;
			}
		}

		.photo-search {
			margin-bottom: 18px;

			> button {
				width: 100%;
				height: 39px;
				border: none;
				background: #92EB8E 0 0 no-repeat padding-box;
				box-shadow: 0 3px 20px #0000000D;
				border-radius: 15px;
				font: normal normal bold 16px/19px 'Helvetica-Regular';
				letter-spacing: 0;
				color: #FFFFFF;
			}
		}

		//& > h3 {
		//    margin-bottom: 30px;
		//}
	}
}
</style>
