<template lang="pug">
.home
	filters(@fetchPageData="handleFetchData")
	posts
	ActiveOffers
</template>

<script>
import Filters from "../components/home/Filters";
import Posts from "../components/home/Posts";
import ActiveOffers from "../components/home/ActiveOffers.vue";
import tokenService from "../services/token.service";

export default {
	name: 'Home',
	components: {
		ActiveOffers,
		Filters,
		Posts
	},
	methods: {
		handleFetchData(query) {
			if (query !== null) this.$store.dispatch("posts/fetchPosts", query)
			else this.$store.dispatch("posts/fetchPosts")
			this.$store.dispatch("user/userData").then(res => {
				if (res.data.biography_id && !Number(tokenService.getItem("activeProfile")) &&
					!Number(tokenService.getItem("activeChildId"))) {
					tokenService.setItem('activeProfile', res.data.biography_id)
				}
			})
			this.$store.dispatch("user/fetchActiveOffers")
			this.$store.dispatch("posts/fetchFilters")
			this.$store.dispatch("user/fetchUserNotifications")
		}
	}
}
</script>

<style lang="scss" scoped>
.home {
	min-height: 80vh;
	display: grid;
	grid-template-columns: 290px auto 345px;
	grid-column-gap: 20px;
	padding-top: 20px;

	h1 {
		margin-bottom: 20px;
	}
}
</style>
